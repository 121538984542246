import { formatDateTime, formatShortDateWithWeekday } from "@clipboard-health/date-time";
import { InternalLink, Text, useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeOutlined";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import InfoIcon from "@mui/icons-material/Info";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Card, CardContent, IconButton, Stack } from "@mui/material";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { formatDollarsAsUsd } from "@src/appV2/lib/Money/utils/currency";
import { generatePath } from "react-router-dom";

import { ShiftDuration } from "../MyShifts/components/ShiftCard/ShiftDuration";
import { WorkedBreakPayRequestInfoDialog } from "./WorkedBreakPayRequestInfoDialog";

export interface WorkedBreakPayRequestCardProps {
  shiftStart: string;
  shiftEnd: string;
  facilityUserId?: string;
  facilityName: string;
  breakPay: number;
  approvalDeadline: Date;
  timeZone: string;
}

export function WorkedBreakPayRequestCard(props: WorkedBreakPayRequestCardProps) {
  const {
    shiftStart,
    shiftEnd,
    timeZone,
    facilityUserId,
    facilityName,
    breakPay,
    approvalDeadline,
  } = props;

  const modalState = useModalState();

  return (
    <>
      <Card elevation={3}>
        <CardContent>
          <Stack spacing={2}>
            <Stack spacing={1}>
              <Text bold>{formatShortDateWithWeekday(shiftStart, { timeZone })}</Text>
              <Stack direction="row" spacing={4} alignItems="center">
                <LocationOnIcon sx={{ color: (theme) => theme.palette.grey[700] }} />
                <Text variant="body2">
                  {isDefined(facilityUserId) ? (
                    <InternalLink
                      to={generatePath(DeprecatedGlobalAppV1Paths.FACILITY_DETAIL, {
                        facilityId: facilityUserId,
                      })}
                    >
                      {facilityName}
                    </InternalLink>
                  ) : (
                    facilityName
                  )}
                </Text>
              </Stack>
              <Stack direction="row" spacing={4} alignItems="center">
                <AccessTimeFilledIcon sx={{ color: (theme) => theme.palette.grey[700] }} />
                <ShiftDuration
                  isEmphasized={false}
                  shift={{ start: shiftStart, end: shiftEnd }}
                  timeZone={timeZone}
                />
              </Stack>
              <Stack direction="row" spacing={4} alignItems="center">
                <AttachMoneyIcon sx={{ color: (theme) => theme.palette.grey[700] }} />
                <Stack>
                  <Text>{formatDollarsAsUsd(breakPay)} pending approval</Text>
                  <Text variant="body2">Skipped break time payment</Text>
                </Stack>
              </Stack>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <Text variant="body2">
                Additional break payment pending workplace approval by{" "}
                {formatDateTime(approvalDeadline, { timeZone })}.
              </Text>
              <IconButton
                aria-label="Show break payment info"
                onClick={() => {
                  modalState.openModal();
                }}
              >
                <InfoIcon />
              </IconButton>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
      <WorkedBreakPayRequestInfoDialog
        modalState={modalState}
        approvalDeadlineLabel={formatDateTime(approvalDeadline, { timeZone })}
      />
    </>
  );
}
